.DateBetweenComponent-container {
  display: flex;
  flex-direction: column;
  width: 95%;
}
  .DateBetweenComponent-container-text {
    color: var(--gray600);
    margin-bottom: 4px;
  }
  .DateBetweenComponent-container-inputContainer {
    position: relative;
    display: inline-block;
  }
    .DateBetweenComponent-container-inputContainer-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      border-radius: 15px;
      border: 1px solid var(--gray300);
      background: var(--white);
      padding: 10px;
      width: 100%;
      outline: none;
      font-family: 'Lato';
      color: var(--gray700);
    }

    .DateBetweenComponent-container-inputContainer-input::placeholder {
      color: var(--gray300);
      white-space: nowrap;
      overflow: visible;
    }
  .DateBetweenComponent-container-error {
    color: var(--errorPrimary);
  }

  ::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }

 input[type=date]{
  text-transform: uppercase;
 }

