.popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  cursor: auto;
}

.popupInfo-card {
  display: flex;
  width: 320px;
  padding: 16px;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  background: var(--neutrals-white, #fff);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  z-index: 3;
}

.popupInfo-button {
  display: flex;
  color: var(--primary500);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  border: 0;
  background-color: var(--white);
  cursor: pointer;
}

.popupInfo-button:active {
  color: var(--primary700);
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.popupInfo-text {
  display: flex;
  color: var(--neutrals-gray-400, #858585);
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.popupInfo-text:hover {
  color: var(--secondary500, #ff4179);
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.popupInfo-title {
  color: var(--neutrals-gray-700, #333);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.popupInfo-title:hover {
  color: var(--secondary500, #ff4179);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
}

.lowerPart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.simpleContainer{
    display: flex;
    flex-direction: row;
    width: fit-content;
}