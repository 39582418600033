.crearInmobiliariaModal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    .crearInmobiliariaModal-container-inputTitle {
        color: var(--gray700);
        margin-right: auto;
    }
    .crearInmobiliariaModal-container-type {
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        margin-right: auto;
    }
        .crearInmobiliariaModal-container-type-title {
            color: var(--gray300);
            margin-bottom: 10px;
        }
        .crearInmobiliariaModal-container-type-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
            .crearInmobiliariaModal-container-type-list-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                cursor: pointer;
            }
                .crearInmobiliariaModal-container-type-list-item-circle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 20px;
                    width: 20px;
                    border: 2px solid var(--primary500);
                    border-radius: 25px;
                    margin-right: 5px;
                }
                    .crearInmobiliariaModal-container-type-list-item-circle-inner {
                        height: 70%;
                        width: 70%;
                        background-color: var(--primary500);
                        border-radius: 25px;
                        transition: all 0.5s ease;
                    }
                .crearInmobiliariaModal-container-type-list-item-text {
                    color: var(--gray300);
                }
    .crearInmobiliariaModal-container-title {
        color: var(--gray700);
        margin-bottom: 10px;
    }
    .crearInmobiliariaModal-container-subTitle {
        color: var(--gray300);
        margin-bottom: 38px;
        text-align: center;
    }
    .crearInmobiliariaModal-container-inputList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }
        .crearInmobiliariaModal-container-inputList > *{
            width: 47.5%;
            margin: 10px 0;
        }
    .crearInmobiliariaModal-container-buttonList {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;
    }
        .crearInmobiliariaModal-container-buttonList > * {
            width: 25%;
        }
    .crearInmobiliariaModal-container-buttonWrap {
        width: 20%;
    }