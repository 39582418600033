.polizasDueñoVivienda-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
    justify-content: space-between;
}
    .polizasDueñoVivienda-container-data {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin-bottom: 20px;
    }
        .polizasDueñoVivienda-container-data-title {
            color: var(--gray700);
            margin-bottom: 20px;
        }
        .polizasDueñoVivienda-container-data-section {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 11px;
        }
            .polizasDueñoVivienda-container-data-section > * {
                width: 32%;
                margin-bottom: 30px;
            }
        .polizasDueñoVivienda-container-data-section-threeList {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 32%;
            margin-bottom: 30px;
        }
            .polizasDueñoVivienda-container-data-section-threeList > * {
                width: 31%;
            }
        .polizasDueñoVivienda-container-data-section-selector {
            width: 32%;
            height: 64.4px;
            position: relative;
            margin-bottom: 30px;
        }
            .polizasDueñoVivienda-container-data-section-selector-label {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 5px;
            }
                .polizasDueñoVivienda-container-data-section-selector-label-text {
                    color: var(--gray300);
                }
                .polizasDueñoVivienda-container-data-section-selector-label-required {
                    color: var(--errorPrimary);
                }
        .polizasDueñoVivienda-container-data-section-gender {
            width: 32%;
            height: 64.4px;
            margin-bottom: 30px;
        }
            .polizasDueñoVivienda-container-data-section-gender-label {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 5px;
            }
                .polizasDueñoVivienda-container-data-section-gender-label-text {
                    color: var(--gray300);
                }
                .polizasDueñoVivienda-container-data-section-gender-label-required {
                    color: var(--errorPrimary);
                }
            .polizasDueñoVivienda-container-data-section-gender-list {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-top: 15px;
            }
                .polizasDueñoVivienda-container-data-section-gender-list-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                    .polizasDueñoVivienda-container-data-section-gender-list-item-circle {
                        height: 16px;
                        width: 16px;
                        border-radius: 25px;
                        border: 2px solid var(--gray300);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                        .polizasDueñoVivienda-container-data-section-gender-list-item-circle-inner {
                            height: 12px;
                            width: 12px;
                            border-radius: 25px;
                        }
                    .polizasDueñoVivienda-container-data-section-gender-list-item-text {
                        color: var(--gray500);
                        margin-left: 5px;
                    }
        .polizasDueñoVivienda-container-data-section-invis {
            width: 32%;
            margin-bottom: 30px;
        }
        .polizasDueñoVivienda-container-data-section-phoneInput {
            width: 32%;
            margin-bottom: 30px;
        }
            .polizasDueñoVivienda-container-data-section-phoneInput-label {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 5px;
            }
                .polizasDueñoVivienda-container-data-section-phoneInput-label-text {
                    color: var(--gray300);
                }
                .polizasDueñoVivienda-container-data-section-phoneInput-label-required {
                    color: var(--errorPrimary);
                }
            .polizasDueñoVivienda-container-data-section-phoneInput-inputs {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
                .polizasDueñoVivienda-container-data-section-phoneInput-inputs-inputWrapp {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border: 1px solid var(--gray200);
                    box-sizing: border-box;
                    border-radius: 15px;
                    background-color: var(--transparent);
                    padding: 10px;
                    width: 100%;
                }
                    .polizasDueñoVivienda-container-data-section-phoneInput-inputs-inputWrapp-text {
                        color: var(--gray400);
                        margin-right: 5px;
                    }
                    .polizasDueñoVivienda-container-data-section-phoneInput-inputs-inputWrapp-input {
                        border: none;
                        outline: none;
                        color: var(--gray700);
                        margin: 0;
                        padding: 0;
                        width: 95%;
                        height: 23px;
                        font-family: Lato, sans-serif;
                    }