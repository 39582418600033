.polizasItem-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 20px;
    justify-content: space-between;
}
    .polizasItem-container-data {
        display: flex;
        flex-direction: column;
        width: 75%;
        margin-bottom: 20px;
    }
        .polizasItem-container-data-title {
            color: var(--gray700);
            margin: 0;
            margin-bottom: 10px;
        }
        .polizasItem-container-data-section {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 20px;
        }
        .polizasItem-container-data-section > * {
            width: 31%;
            margin-bottom: 10px;
            margin-right: 20px;
        }
        .polizasItem-container-data-label {
            color: var(--gray300);
        }
        .polizasItem-container-data-item {
            display: flex;
            flex-direction: column;
        }
            .polizasItem-container-data-item-title {
                color: var(--gray700);
                margin-bottom: 20px;
            }
            .polizasItem-container-data-item-data {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 28px;
            }
                .polizasItem-container-data-item-data > * {
                    width: 32%;
                }
                .polizasItem-container-data-item-data-list {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    width: 32%;
                }
                    .polizasItem-container-data-item-data-list > * {
                        width: 31%;
                    }
                .polizasItem-container-data-item-data-selector {
                    width: 32%;
                    height: 64.4px;
                    position: relative;
                }
                    .polizasItem-container-data-item-data-selector-label {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 5px;
                    }
                        .polizasItem-container-data-item-data-selector-label-text {
                            color: var(--gray300);
                        }
                        .polizasItem-container-data-item-data-selector-label-required {
                            color: var(--errorPrimary);
                        }
            .polizasItem-container-data-item-map {
                width: 100%;
                height: 284px;
                position: relative;
            }
                .polizasItem-container-data-item-map-markerContainer {
                }